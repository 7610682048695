.paragraph-with-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: $s-size 15rem $s-size 15rem;
    p {
        max-width: 65%;
    }
    @media (max-width: $preview-mobile-breakpoint) {
        padding: $s-size $m-size $s-size $m-size;
        p {
            max-width: 90%;
        }
    }
}