.app-nav {
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 999;
    padding: 0 $m-size 0 $m-size;
    @media (max-width: $mobile-breakpoint) {
        align-items: normal;
    }
}

.app-nav__mobile-expand {
    margin-top: 1rem;
    font-size: 3rem!important;
    &:hover {
        color: $primary;
        cursor: pointer;
    }
}

.app-nav__wrapper {
    display: flex;
    align-items: center;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 20%;
    }
}

.app-nav__logo__img {
    height: 25px;
    width: 25px;
    margin-right: $s-size;
}

.app-nav__logo {
    color: $font-default;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-right: $m-size;
}

.app-nav__logo__title {
    font-size: $medium-font;
    margin: 0;
}

.app-nav__links {
    transition: all .25s;
    display: flex;
    @media (max-width: $mobile-breakpoint) {
        display: block;
        overflow: hidden;
        max-height: 0;
        width: 100%;
        &.active {
            max-height: 500px;
            padding: $m-size 0;
            width: unset;
        }
    }
}

.app-nav__links a {
    color: $font-default;
    text-decoration: none;
    padding: $s-size $m-size;
    border-radius: 3px;
    transition: background-color 1s;
    display: block;
    text-transform: uppercase;
    font-size: $small-font;
    &:hover {
        background-color: rgba($primary, .1);
    }
    @media (max-width: $mobile-breakpoint) {
        padding: $s-size $m-size;
        width: 100%;
    }
}