.editor-component {
    position: relative;
    display: block;
    cursor: pointer;
}

.editor-component__overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-top: 1px rgba($dark, 0.2) solid;
    border-bottom: 1px rgba($dark, 0.2) solid;
    transition: all 1s;
}

.editor-component__overlay__toolbar {
    position: absolute;
    right: $m-size;
    top: 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 1rem 0px 1rem;
    background-color: $light;
    border-radius: 10px;
}

.editor-component__overlay__option {
    margin: 10px 2px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid $dark;
    opacity: 0.5;
    &:hover {
        opacity: 0.9;
    }
}