.icons-with-description {
    min-height: 130px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-around;
    overflow: hidden;
    padding: $m-size 0;
    @media (max-width: 1050px) {
        flex-direction: column;
        min-height: 390px;
        padding: $m-size 0;
    }
}

.icons-with-description__wrapper {
    flex: 1;
    min-height: 130px;
    width: 33.33333%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 0;
    @media (max-width: 1050px) {
        padding: 10px;
        width: 100%;
    }
}

.icons-with-description__icon {
    height: 40%;
    width: 40%;
    min-width: 120px;
    min-height: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px;
    margin: 0 auto;
}

.icons-with-description__title {
    font-size: $m-size;
    padding-bottom: 1rem;
    max-width: 70%;
    margin: 2rem auto 0;
}

.icons-with-description__desc {
    max-width: 65%;
    margin: 2rem auto 0;
    overflow: hidden;
    max-height: 200px;
}