.theme-picker {
  border-top: rgba($primary, 0.2) solid 1px;
  padding: $s-size 0;
}
.theme-picker__title {
  font-size: $small-font;
}
.theme-picker__container {
  display: flex;
  flex-wrap: wrap;
}
.theme-picker__item {
  width: 33.33333%;
  transition: all .4s;
  color: white;
  &:hover {
    transform: scale(0.95);
    cursor: pointer;
    color: $font-default;
  }
}
.theme-picker__item.active {
  transform: scale(0.95);
  color: $font-default;
}
.theme-picker__item__name {
  font-weight: bold;
  font-size: $small-font;
  margin: 2px 0;
}
.theme-picker__item__preview {
  display: flex;
  flex-wrap: wrap;
}
.theme-picker__item__preview__color {
  width: calc(50% - 4px);
  margin: 1px;
  height: 70.98px;
}