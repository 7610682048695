.about-me-alt {
    width: 100%;
    overflow: hidden;
}

.about-me-alt__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.about-me-alt__top {
    height: 150px;
    width: 100%;
}

.about-me-alt__profile {
    height: 200px;
    width: 200px;
    margin: 0 auto;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: 50px;
}

.about-me-alt__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    padding: 120px $l-size $m-size $l-size;
    p {
        max-width: 65%;
    }
    @media (max-width: $preview-mobile-breakpoint) {
        padding: 120px $s-size $m-size $s-size;
        p {
            max-width: 90%;
        }
    }
}