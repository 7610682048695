.about-me {
    overflow: hidden;
    width: 100%;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $l-size 0 $l-size 0;
    margin: 0 auto;
}

.about-me__wrapper {
    max-width: 1300px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    @media (max-width: $preview-mobile-breakpoint) {
        flex-direction: column;
        justify-content: center;
    }
}

.about-me__img-wrapper {
    display: flex;
    align-items: center;
    width: 400px;
    height: 400px;
    padding: 0;
    @media (max-width: $preview-mobile-breakpoint) {
        width: 250px;
        height: 250px;
        margin: 8rem 0 0 0;
    }
}

.about-me__img-container {
    width: 100%;
    height: 100%;
    padding: 0;
    box-shadow: $m-size $m-size 0 whitesmoke;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-me__text-wrapper {
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 3rem;
    height: 100%;
    width: 45%;
    @media (max-width: $preview-mobile-breakpoint) {
        width: 100%;
        align-self: center;
        padding: 0 1.5rem;
    }
}

.about-me__title {
    margin-top: -3rem;
    margin-bottom: $m-size;
    font-size: 6rem;
    padding: 2rem 0;
    font-weight: 500;
}

.about-me__description {
    font-size: 1.8rem;
    text-align: left;
    padding-left: $m-size;
}