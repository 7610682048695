//font-sizing
$small-font: 1.3rem;
$default-font: 1.6rem;
$medium-font: 2rem;
$large-font: 2.8rem;

//colors
$dark: #1b262c;
$primary: #3f51b5;
$secondary: #0f4c75;
$light: #f9f9f9;
$font-default: #333;
//spacing
$s-size: 1.5rem;
$m-size: 2.5rem;
$l-size: 5rem;

//breakpoints
$mobile-breakpoint: 720px;
$preview-mobile-breakpoint: 1320px;