.image-gallery-inline {
    height: 350px;
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    @media (max-width: 1050px) {
        flex-direction: column;
        height: 900px;
    }
}

.image-gallery-inline__photo {
    flex: 1;
    height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1050px) {
        height: 300px;
        width: 100%;
    }
}