.simple-footer {
    overflow: hidden;
    max-height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.7rem 16rem 0.7rem 16rem;
    @media (max-width: $preview-mobile-breakpoint) {
        padding: 1rem $l-size 1rem $l-size;
    }
    p {
        max-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 550;
    }
}