.footer-with-logo {
    overflow: hidden;
    max-height: 25vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 0.5rem 16rem 0.5rem 16rem;
    p {
        max-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 550;
    }
    @media (max-width: $preview-mobile-breakpoint) {
        padding: 1rem $l-size 1rem $l-size;
        p {
            max-width: 90%;
        }
    }
}

.footer-with-logo__logo {
    margin: 0.5rem;
    height: 60px;
    width: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}