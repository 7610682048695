.image-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-blend-mode: overlay;
  background: url("../../images/homepage/background.jpg"), rgba(255,255,255,.5) no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $mobile-breakpoint) {
    height: calc(100vh - 2rem);
}
}
.image-section__logo {
  width: 100px;
  height: 100px;
  margin-right: $l-size;
  @media (max-width: $mobile-breakpoint) {
    margin-right: $s-size;
  }
}
.image-section__description, .image-section__title {
  margin: 0 0 $s-size 0;
}
.image-section__arrow {
  font-size: 3rem!important;
  color: $font-default;
  position: absolute;
  bottom: 4rem;
  margin-left: -1.5rem;
  left: 50%;
}
.image-section__login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}