.simple-form {
  padding-top: 10%;
}
.simple-form__intro {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column-reverse;
  }
}
.simple-form__back {
  position: absolute;
  top: 20px;
  right: 20px;
}
.simple-form__content {
  margin-right: $m-size;

  p {
    text-align: justify;
  }
}
.simple-form__title {
  font-size: $large-font;
  color: $primary;
}
.simple-form__input label, .simple-form__input input {
  font-size: $medium-font!important;
  padding-bottom: 10px;
}
.simple-form__input {
  @media (max-width: $mobile-breakpoint) {
    margin-bottom: $s-size!important;
  }
}
.simple-form__form {
  display: flex;
  align-items: flex-end;
  max-width: 400px;
  justify-content: space-between;

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: unset;
  }
}
