.features-section {
  padding: 5rem 3rem;
}

.features-section__title {
  text-align: center;
  font-weight: 300;
  margin-bottom: 4rem;
}

.features-section__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  
  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    padding: 3rem 0;
    justify-content: center;
  }
}
.features-section__item {
  text-align: center;
  max-width: 26%;

  @media (max-width: $mobile-breakpoint) {
    margin: $l-size auto;
    max-width: 75%;
  }
}
.features-section__item__title {
  font-size: $large-font;
  margin: $m-size 0 0 0;
}
.features-section__item__icon {
  font-size: 8rem!important;
  color: $primary;
}