.cards-section {
  padding: $l-size $m-size;
  display: flex;
  flex-wrap: wrap;
}
.cards-section__title {
  width: 100%;
  padding-bottom: $l-size;

  h2 {
    font-weight: lighter;
  }
}
.cards-section__card {
  height: 300px;
  margin: 10px;
  width: calc(50% - 20px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  transition: all 1s!important;
  color: #666!important;

  &:hover {
    background-color: rgba($primary, .1)
  }

  @media (max-width: $mobile-breakpoint) {
    width: calc(100% - 20px);
  }
}
.cards-section__card__icon {
  font-size: 4rem!important;
  margin: $s-size;
}
.cards-section__card__description {
  margin: 0;
}