.social-media {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media__wrapper {
    width: 100%;
    max-width: 2000px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.social-media__icons-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
}

.social-media__container {
    flex: 1;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 5%;
}

.social-media__icon-link {
    height: 100px;
    width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media__icon {
    font-size: 9rem !important;
}

.social-media__desc {
    width: 100%;
    text-align: center;
    text-decoration: none;
    padding: 5px;
}