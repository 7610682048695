html {
    font-size: 62.5%;
    scroll-behavior: smooth !important;
    height: 100%;
}

body {
    height: 100%;
    font-family: "Roboto", sans-serif;
    font-size: $default-font;
    color: $font-default;
}

#root {
    min-height: 100%;
}

* {
    box-sizing: border-box;
}