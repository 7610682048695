.backgroundimg-header {
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
    background-attachment: fixed;
    padding: $s-size;
}

.backgroundimg-header__wrapper {
    max-width: 1400px;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 3rem;
    height: 100%;
    width: 65%;
    @media (max-width: $mobile-breakpoint) {
        width: 90%;
        padding: 0 $s-size;
    }
}

.backgroundimg-header__break {
    width: 60%;
    height: 2px;
    margin-top: 1rem;
    margin-bottom: 8rem;
}

.backgroundimg-header__title {
    margin-top: 15rem;
    margin-bottom: 0;
    font-size: 7rem;
    padding: 2rem 0;
    font-weight: 500;
    text-align: center;
    @media (max-width: $mobile-breakpoint) {
        font-size: 6rem;
    }
}

.backgroundimg-header__description {
    font-size: 1.8rem;
    text-align: center;
    text-shadow: whitesmoke;
}

.backgroundimg-header__button {
    bottom: 10rem;
    margin-top: auto;
    border-radius: 50px;
    padding: $s-size $m-size $s-size $m-size;
    z-index: 10;
    text-decoration: none;
    &:hover {
        opacity: 0.6;
    }
}