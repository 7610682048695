.header-dark__container {
    max-width: 1400px;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        justify-content: center;
    }
}

.header-dark__img {
    max-width: 100%;
    max-height: 100%;
    @media (max-width: $preview-mobile-breakpoint) {
        max-height: 250px;
        max-width: 70%;
        padding-bottom: $s-size;
        margin-top: -2rem;
    }
}

.header-dark__img-wrapper {
    width: 40%;
    height: 40%;
    @media (max-width: $preview-mobile-breakpoint) {
        width: 80%;
        padding: 3rem 0 0 0;
    }
}

.header-dark__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 3rem;
    height: 100%;
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: $preview-mobile-breakpoint) {
        width: 90%;
    }
}

.header-dark__break {
    width: 80%;
    height: 3px;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.header-dark__title {
    margin-bottom: 0;
    font-size: 6rem;
    padding: 2rem 0;
    max-width: 100%;
    word-break: break-word;
    white-space: pre-wrap;
    font-weight: 500;
    @media (max-width: $preview-mobile-breakpoint) {
        font-size: 5rem;
    }
    @media (max-width: $mobile-breakpoint) {
        font-size: 4rem;
    }
}

.header-dark__description {
    font-size: 1.8rem;
    max-width: 100%;
    text-align: center;
    word-break: break-word;
    white-space: pre-wrap;
}

.header-dark__button {
    bottom: 10rem;
    margin-top: 3rem;
    border: 2px solid $light;
    border-radius: 50px;
    padding: $s-size $m-size $s-size $m-size;
    z-index: 10;
    text-decoration: none;
    &:hover {
        opacity: 0.6;
    }
}