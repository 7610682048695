.menu-slim {
    position: relative;
    width: 100%;
    padding: 1rem 2rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.menu-slim__brand {
    display: flex;
    flex-grow: 1;
    align-items: center;
    max-width: 40%;
    overflow: hidden;
    @media (max-width: $mobile-breakpoint) {
        min-width: 100%;
        max-width: 100%;
    }
}

.menu-slim__expand {
    display: none!important;
    cursor: pointer;
    @media (max-width: $mobile-breakpoint) {
        display: inline-block!important;
        margin-left: auto;
    }
}

.menu-slim__logo {
    max-height: 40px;
    margin-right: 20px;
    max-width: 100%;
}

.menu-slim__links {
    display: flex;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        position: absolute;
        width: 100%;
        transform: scaleY(0);
        transition: transform 1s;
        display: none;
        top: 100%;
        left: 0;
        &.active {
            height: auto;
            display: block;
            padding: 20px 0;
            transform: scaleY(1);
        }
    }
}

.menu-slim__link {
    padding: 0 20px;
    margin: 0 10px;
    text-decoration: none;
    opacity: 1;
    transition: opacity .5s;
    &:hover {
        opacity: .6;
    }
    @media (max-width: $mobile-breakpoint) {
        display: block;
        padding: 10px 20px;
    }
}

.menu-slim__title {
    font-size: 1.8rem;
    margin: 0;
    font-weight: 300;
}