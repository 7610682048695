.editor {
    padding-top: 50px;
    display: flex;
    min-height: 100vh;
    width: 100%;
}

.editor__tray {
    position: fixed;
    top: 54px;
    z-index: 2;
    background: #fefefe;
    left: 0;
    width: 500px;
    height: 100%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
    padding: $m-size $m-size 20rem $m-size;
    overflow-y: scroll;
}

.editor__tray__navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 500px;
}

.editor__tray__title {
    color: #444;
    font-weight: lighter;
    display: flex;
    align-items: center;
    margin-bottom: $s-size;
    img {
        margin-right: $m-size;
        height: 150px;
        width: 150px;
    }
}

.editor__tray__controls {
    margin-left: $m-size;
}

.editor__preview {
    margin-left: 500px;
    width: 100%;
    min-height: 100%;
}

.editor__preview::after {
    content: 'Tutaj pojawiają się nowe elementy. Dodaj jakiś!';
    text-align: center;
    margin: 30px auto;
    display: block;
}

.editor__preview__back {
    position: fixed!important;
    bottom: 20px;
    right: 20px;
    z-index: 99;
}
.editor__tray__add-component__item {
    border-radius: 10px;
    overflow: hidden;
    transition: all .3s;
    margin: 10px 0;
    position: relative;
    p {
        width: 100%;
        background: #333;
        padding: 5px 20px;
        text-align: center;
        margin: 0;
        font-size: $small-font;
        color: white;
    }
    img {
        width: 100%;
        display: block;
    }
    &:hover {
        cursor: pointer;
    }
}

.editor__tray__add-component__item.active {
    transform: scale(0.95);
}

.editor__tray__select-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}