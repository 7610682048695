.footer-section {
  padding: $s-size 0;
  background: white;
  color: #888;
  box-shadow: 0 15px 20px #888;
}
.footer-section__wrapper {
  display: flex;
  align-items: center;
}
.footer-section__link {
  margin-right: 3rem;
  color: #888;
  text-decoration: none;
}
.footer-section__title {
  font-weight: bold;
  margin-right: $l-size;
  font-size: $medium-font;
}