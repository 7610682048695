.simple-line {
    width: 100%;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.simple-line__line {
    width: 50%;
    max-width: 1000px;
    height: 3px;
    border-radius: 2px;
    @media (max-width: $preview-mobile-breakpoint) {
        width: 70%;
    }
}