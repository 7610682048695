.cta-section {
  background: $light;
}
.cta-section--on-top {
  padding-top: 50px;
}
.cta-section__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $l-size $m-size;

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column-reverse;
  }
}
.cta-section__content {
  max-width: 600px;
}
.cta-section__text {
  text-align: justify;
}
.cta-section__title {
  font-size: $large-font;
  font-weight: 300;
}
