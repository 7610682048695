.simple-photo {
    height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.simple-photo__paralax {
    height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}